<template>
  <li class="tile">
    <button
      v-if="!XcontrolMother"
      class="tile__link"
      :class="[{ 'tile__link-active': Valid }, { 'tile__link--wo-image': !ImageOnPath.length }]"
      @click.prevent="onClick"
    >
      <span class="tile__value" :class="{ 'tile__value--w-image': ImageOnPath.length }">
        <span class="badge" v-if="MIQVzModell" :style="{ 'background-color': MIQVzModell }"></span>

        <span v-html="Lanqkey01"></span>
      </span>

      <span class="tile__subvalue"></span>
      <div
        class="tile__image"
        v-if="ImageOnPath"
        :style="{ 'background-image': 'url(' + getImageHandlerStyleSrc(ImageOnPath, { width: 75, maxheight: 120 }) + ')' }"
      ></div>
    </button>

    <router-link :to="{ path: '/pages/' + Xnodekey }" class="tile__link" :class="{ 'tile__link--wo-image': !ImageOnPath.length }" v-else>
      <span class="tile__value" :class="{ 'tile__value--w-image': ImageOnPath.length }">
        <span class="badge" v-if="MIQVzModell" :style="{ 'background-color': MIQVzModell }"></span>

        <span v-html="Lanqkey01"></span>
      </span>
      <span class="tile__subvalue"></span>
      <div class="tile__image" v-if="ImageOnPath">
        <img :src="getImageHandlerSrc(ImageOnPath, { width: 75, maxheight: 120 })" />
      </div>
    </router-link>

    <div class="tile__hint" v-if="InfoTooltipNumber">
      <el-button type="text" @click="onClickTooltip">
        <i class="el-icon-info font--regular-plus"></i>
      </el-button>
    </div>
  </li>
</template>

<script>
import { get } from 'lodash';
import mixins from '@/shared/mixins/index';

export default {
  name: 'Tile',

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    ImageOnPath() {
      return get(this.data, 'ImageOnPath');
    },

    InfoTooltipNumber() {
      return get(this.data, 'InfoTooltipNumber');
    },

    Lanqkey01() {
      return get(this.data, 'Lanqkey01');
    },

    MIQVzModell() {
      return get(this.data, 'MIQVzModell');
    },

    Valid() {
      return get(this.data, 'Valid');
    },

    XcontrolMother() {
      return get(this.data, 'XcontrolMother');
    },

    Xnodekey() {
      return get(this.data, 'Xnodekey');
    }
  },

  methods: {
    onClick() {
      // Send event to parent
      this.$emit('click-tile', this.data);
    },

    onClickTooltip() {
      this.$store.commit('dialog/OPEN_DIALOG', {
        loading: true
      });

      this.$store
        .dispatch('GET_TOOLTIP', this.data)
        .then(result => {
          const RESPONSE = get(result, 'data.RESPONSE');
          this.$store.commit('dialog/OPEN_DIALOG', {
            content: RESPONSE,
            loading: false
          });
        })
        .catch(() => {
          this.$store.commit('dialog/CLOSE_DIALOG');
        });
    }
  }
};
</script>
