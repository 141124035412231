<template>
  <el-form @submit.prevent="onSubmit" ref="form" :model="filters">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="16" class="p-l-0 p-r-0">
        <el-col :xs="12" :sm="8">
          <el-form-item :label="$t('FROM')">
            <el-date-picker v-model="filters.DATESTART" type="date" class="el-input--full-width" placeholder></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :xs="12" :sm="8">
          <el-form-item :label="$t('TO')">
            <el-date-picker v-model="filters.DATEEND" type="date" class="el-input--full-width" placeholder></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :xs="12" :sm="8">
          <el-form-item :label="$t('EMAIL_ADDRESS')">
            <el-input v-model="filters.USERTOSEARCH" class="el-input--full-width" placeholder></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="12" :sm="8">
          <el-form-item :label="$t('CUSTOMER')">
            <el-input v-model="filters.CUSTOMER" class="el-input--full-width" placeholder></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="12" :sm="8">
          <el-form-item :label="$t('SESSION_NUMBER')">
            <el-input v-model="filters.SESSIONTOSEARCH" class="el-input--full-width" placeholder></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="12" :sm="8">
          <el-form-item :label="$t('MACHINE')">
            <el-input v-model="filters.MACHINENAME" class="el-input--full-width" placeholder></el-input>
          </el-form-item>
        </el-col>
      </el-col>

      <el-col :xs="24" :sm="8" class="p-l-0 p-r-0">
        <el-col :xs="24" :md="24">
          <el-form-item label>
            <el-button type="primary" class="w-100" @click="onSubmit">{{ $t('SEARCH') }}</el-button>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :md="24">
          <el-form-item label>
            <el-button type="primary" class="w-100" @click="onReset">{{ $t('CLEAR_FILTER') }}</el-button>
          </el-form-item>
        </el-col>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
/* eslint-disable no-unused-vars */
import { clone, forEach } from 'lodash';

export default {
  name: 'WEBSESSION-FILTER',

  data() {
    return {
      filters: {
        DATESTART: '',
        DATEEND: '',
        USERTOSEARCH: '',
        MACHINENAME: '',
        PRODUCT: '',
        CUSTOMER: '',
        SESSIONTOSEARCH: ''
      }
    };
  },

  computed: {
    filterAvailable() {
      var filledFilters = this.getFilledFilters();
      return filledFilters.length > 0;
    }
  },

  methods: {
    getFilledFilters() {
      var filledFilters = [];

      forEach(this.filters, (value, key, filter) => {
        if (value !== '') {
          filledFilters.push({ key: value });
        }
      });

      return filledFilters;
    },

    getTimestampByDate(stringDate) {
      var date = new Date(stringDate).getTime() / 1000;
      return date;
    },

    init() {},

    onReset() {
      this.filters.DATESTART = '';
      this.filters.DATEEND = '';
      this.filters.USERTOSEARCH = '';
      this.filters.MACHINENAME = '';
      this.filters.PRODUCT = '';
      this.filters.CUSTOMER = '';
      this.filters.SESSIONTOSEARCH = '';

      this.$emit('reset-filter-form');
    },

    onSubmit() {
      const that = this;
      let filters = clone(this.filters, true);

      if (filters.DATESTART) {
        filters.DATESTART = this.getTimestampByDate(filters.DATESTART);
      }
      if (filters.DATEEND) {
        filters.DATEEND = this.getTimestampByDate(filters.DATEEND);
      }

      const data = JSON.stringify(filters);
      console.log('onSubmit', data);

      this.$emit('submit-filter-form', filters);
    }
  }
};
</script>

<style lang="scss"></style>
