<template>
  <div class="webnews p-t-2">
    <el-row>
      <el-col :xs="24" :sm="{ span: 12, offset: 6 }" :md="{ span: 10, offset: 7 }" class="m-b-2">
        <div class="flex--sm">
          <el-input :placeholder="$t('INPUT_SEARCHSTRING')" v-model="searchString" clearable suffix-icon="el-icon-search"></el-input>
        </div>
      </el-col>

      <el-col :span="24">
        <Table :data="newsNode" :loading="loading" v-on:action-click="onActionClick" v-loading="loading"></Table>

        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalItems"
          :page-size="pageSize"
          :page-sizes="pageSizes"
          @current-change="onChangePage"
          class="text-center m-t-2 m-b-2"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { clone, forEach, get } from 'lodash';
import Table from '@/components/Table';

export default {
  name: 'WEBNEWS',

  components: {
    Table
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      firstLoad: true,
      loading: false,
      minSearchStringCount: 2,
      newsNode: {},
      searchString: ''
    };
  },

  computed: {
    currentPage() {
      return this.$store.state.webnews.pagination.currentPage;
    },

    news() {
      return this.$store.state.webnews.news;
    },

    pageOffset() {
      return this.$store.state.webnews.pagination.pageOffset;
    },

    pageSize() {
      return this.$store.state.webnews.pagination.pageSize;
    },

    pageSizes() {
      return this.$store.state.webnews.pagination.pageSizes;
    },

    totalItems() {
      return this.$store.state.webnews.totalItems;
    }
  },

  mounted() {
    this.init();
    console.log('WEBNEWS mounted...');
  },

  methods: {
    init() {
      this.requestNews();
    },

    onActionClick(data) {
      this.requestNewsContent(data.node);
    },

    onChangePage(page) {
      const offset = page * this.pageSize;

      this.$store.commit('webnews/SET_PAGINATION', {
        currentPage: page,
        pageOffset: offset - this.pageSize,
        pageSize: this.pageSize
      });

      console.log(page, this.pageOffset, this.pageSize);

      this.requestNews();
    },

    onClickSearchButton() {
      this.onSearchInput();
    },

    onSearchInput() {
      this.searchString = this.searchString.toString();
      this.requestNews();
    },

    requestNews() {
      this.loading = true;

      const params = {
        PAGELIMIT: this.pageSize,
        PAGEOFFSET: this.pageOffset,
        SEARCHSTRING: this.searchString
      };

      this.$store
        .dispatch('webnews/GET_NEWS', params)
        .then(result => {
          const RowsCount = get(result, 'data.RESPONSE.RowsCount', '');
          this.newsNode = clone(this.news);

          if (this.firstLoad) {
            this.$store.commit('webnews/SET_TOTAL_ITEMS', {
              totalItems: parseInt(RowsCount)
            });

            this.firstLoad = false;
          }
        })
        .catch(err => {
          throw new Error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    requestNewsContent(node) {
      this.$store.commit('dialog/OPEN_DIALOG', {
        loading: true
      });

      this.$store
        .dispatch('webnews/GET_NEWS_CONTENT', { Value01: node.Value01 })
        .then(result => {
          const RESPONSE = get(result, 'data.RESPONSE', {});
          this.$store.commit('dialog/OPEN_DIALOG', {
            content: RESPONSE,
            loading: false
          });
        })
        .catch(() => {
          this.$store.commit('dialog/CLOSE_DIALOG');
        });
    },

    resetPagination() {
      this.$store.commit('webnews/RESET_PAGINATION');
    },

    searchNewsByString(string) {
      const searchString = string.toLowerCase();

      const newsNode = clone(this.news);
      const items = {};
      forEach(this.news.GuiNodeCh, (news, newsKey) => {
        forEach(news.GridColumnInfo, column => {
          const lowercaseValue = column.toLowerCase();
          const stringIncluded = lowercaseValue.includes(searchString);
          if (stringIncluded) {
            items[newsKey] = news;
          }
        });
      });
      newsNode.GuiNodeCh = items;

      return newsNode;
    },

    slicedNode(node) {
      let start = 0;
      if (this.$store.state.webnews.pagination.currentPage > 1) {
        start =
          this.$store.state.webnews.pagination.pageSize * this.$store.state.webnews.pagination.currentPage -
          this.$store.state.webnews.pagination.pageSize;
      }

      const end = start + this.$store.state.webnews.pagination.pageSize;
      const newsItems = {};
      let index = 0;
      forEach(node.GuiNodeCh, (value, key) => {
        if (index >= start && index < end) {
          newsItems[key] = value;
        }
        index++;
      });

      node.GuiNodeCh = newsItems;

      return node;
    }
  },

  watch: {
    searchString(newValue, oldValue) {
      // Do nothing if value is the same
      if (newValue === oldValue) {
        return;
      }

      // Reset table data if searchString is empty
      if (newValue === '') {
        this.resetPagination();
        this.loading = true;
        setTimeout(() => {
          this.onSearchInput();
          this.loading = false;
        }, 400);
        return;
      }

      if (newValue.length + 1 <= this.minSearchStringCount) {
        return;
      }

      this.onSearchInput();
    }
  },

  destroyed() {
    console.log('WEBNEWS destroyed.');
  }
};
</script>
