<template>
  <div>
    <WEBSESSIONFILTER
      v-on:submit-filter-form="onSubmitFilterForm"
      v-on:reset-filter-form="resetPagination"
      class="p-t-1"
    ></WEBSESSIONFILTER>

    <el-row class="m-t-2">
      <el-col :span="24">
        <Table
          :data="sessions"
          :loading="loadingSessions"
          :action-icon-class="'el-icon-arrow-right'"
          v-on:action-click="onActionClick"
        ></Table>

        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalItems"
          :page-size="pageSize"
          @current-change="onChangePage"
          class="text-center m-t-2 m-b-2"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from 'lodash';
import Table from '@/components/Table';
import WEBSESSIONFILTER from './WEBSESSION_FILTER';
import mixins from '@/shared/mixins/index';

export default {
  name: 'WEBSESSION',

  components: {
    Table,
    WEBSESSIONFILTER
  },

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      filters: {},
      loadingSessions: false,
      sessions: {}
    };
  },

  computed: {
    currentPage() {
      return this.$store.state.websession.pagination.currentPage;
    },

    pageSize() {
      return this.$store.state.websession.pagination.pageSize;
    },

    totalItems() {
      return this.$store.state.websession.totalItems;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.sessions = this.data;
      this.resetPagination();
    },

    onActionClick(node) {
      const Sessionnumber = get(node, 'node.Sessionnumber', '');

      this.$store
        .dispatch('websession/SET_SESSION', { sessionNumber: Sessionnumber })
        .then(result => {
          const RESPONSE = get(result, 'data.RESPONSE');
          if (RESPONSE) {
            console.log('onAction session', RESPONSE);
            // Load node from the response and go to the page
            this.$router.push({ path: '/pages/' + RESPONSE });
            // Reload the page to get translations and other stuff
            window.location.reload();
          }
        })
        .catch(err => {
          console.log('onActionClick err', err);
        });

      console.log('onActionClick', node, Sessionnumber);
    },

    onChangePage(page) {
      this.$store.commit('websession/SET_PAGINATION', {
        currentPage: page,
        pageSize: this.pageSize
      });

      this.onSubmitFilterForm(this.filters);
    },

    onSubmitFilterForm(filters) {
      this.filters = filters;

      this.loadingSessions = true;
      this.$store
        .dispatch('websession/GET_OLD_SESSIONS', {
          data: filters,
          PAGELIMIT: this.pageSize,
          PAGEOFFSET: this.currentPage
        })
        .then(result => {
          const RESPONSE = get(result, 'data.RESPONSE', {});
          this.sessions = RESPONSE;
          this.loadingSessions = false;
        });
    },

    resetPagination() {
      this.$store.commit('websession/RESET_PAGINATION');
    }
  }
};
</script>

<style lang="scss"></style>
