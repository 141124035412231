<template>
  <el-dialog :title="data.Lanqkey01" :visible="visible" :fullscreen="isFullscreen" :width="dialogWidth" @close="onClose">
    <el-input :placeholder="$t('INPUT_SEARCHSTRING')" v-model="searchKey" clearable autofocus>
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
    <ul class="list list--max-height m-t-1">
      <li class="list__item list__item--bordered m-t-0" v-for="item in items" :key="item.key">
        <a href="#" @click.prevent="onSelectItem(item)" class="list__item-link flex valign-center p-t-small p-b-small p-l-small">
          <div v-if="item.ImageOnPath" class="m-r-1">
            <img :src="getImageHandlerSrc(item.ImageOnPath, { width: 40 })" :alt="item.Xnodekey" class="d-block" />
          </div>
          <div>{{ item.Lanqkey01 }}</div>
        </a>
      </li>
    </ul>
  </el-dialog>
</template>

<script>
import * as Browser from '@/services/browser';
import { filter, get } from 'lodash';
import mixins from '@/shared/mixins/index';

export default {
  name: 'WEBLISTCHOOSER',

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    },
    fullscreen: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    visible: {
      type: Boolean,
      required: true
    },
    width: {
      type: String
    }
  },

  computed: {
    GuiNodeCh() {
      return get(this.data, 'GuiNodeCh');
    }
  },

  data() {
    return {
      items: {},
      searchKey: '',
      dialogWidth: this.width,
      isFullscreen: this.fullscreen
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    clear() {
      this.searchKey = '';
    },

    handleDialogVariation() {
      let dialogWidth = this.width;
      let fullscreen = this.fullscreen;

      if (Browser.isDeviceXS() || Browser.isDeviceSM()) {
        dialogWidth = '100%';
        fullscreen = true;
      }

      this.dialogWidth = dialogWidth;
      this.isFullscreen = fullscreen;
    },

    init() {
      if (Array.isArray(this.GuiNodeCh)) {
        this.items = this.GuiNodeCh.slice();
      } else {
        this.items = Object.assign({}, this.GuiNodeCh);
      }
    },

    onClose() {
      // Send event to parent
      this.$emit('close-weblistchooser');
    },

    onSearchItems() {
      setTimeout(() => {
        this.items = filter(this.GuiNodeCh, item => {
          const stringToLower = item.Lanqkey01.toLowerCase();
          const searchToLower = this.searchKey.toLowerCase();

          return stringToLower.includes(searchToLower);
        });
      }, 350);
    },

    onSelectItem(node) {
      this.clear();

      // Send event to parent
      this.$emit('select-weblistchooser-item', node);
    }
  },

  watch: {
    searchKey(newValue, oldValue) {
      if (newValue === '') {
        this.init();
      }

      if (newValue !== oldValue) {
        this.onSearchItems();
      }
    },

    visible(newValue) {
      if (newValue) {
        this.init();
      }
      this.handleDialogVariation();
    }
  }
};
</script>

<style lang="scss">
.list {
  &--max-height {
    @media screen and (max-width: $screen-md) {
      max-height: 70vh;
      overflow: auto;
    }

    @media screen and (min-width: $screen-md) {
      max-height: 60vh;
      overflow: auto;
    }
  }
}
</style>
