export default {
  methods: {
    createImageColumn() {
      return {
        Fieldname: 'IMAGE',
        Title: '',
        Width: '90',
        Align: 'C',
        Sortable: false
      };
    },

    createInfoColumn() {
      return {
        Fieldname: 'INFO',
        Title: '',
        Width: '40',
        Align: 'L',
        Sortable: false
      };
    }
  }
};
