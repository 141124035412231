<template>
  <div
    v-loading="loading"
    :element-loading-text="IndicatorMessage"
    :element-loading-spinner="IndicatorIcon"
    :element-loading-background="IndicatorBackground"
  >
    <!-- Controls for the main page area -->
    <WEBMAINCONFIGU v-if="isControlVisible('WEBMAINCONFIGU')" :data="WEBMAINCONFIGU_DATA"></WEBMAINCONFIGU>
    <WEBMIQMA v-if="isControlVisible('WEBMIQMA')" :data="WEBMIQMA_DATA"></WEBMIQMA>
    <WEBNEWS v-if="isControlVisible('WEBNEWS')" :data="WEBNEWS_DATA"></WEBNEWS>
    <WEBSEARCH v-if="isControlVisible('WEBSEARCH')" :data="WEBSEARCH_DATA"></WEBSEARCH>
    <WEBSESSION v-if="isControlVisible('WEBSESSION')" :data="WEBSESSION_DATA"></WEBSESSION>
    <WEBTILECHOOSER v-if="isControlVisible('WEBTILECHOOSER')" :data="WEBTILECHOOSER_DATA"></WEBTILECHOOSER>
    <WEBTREE v-if="isControlVisible('WEBTREE')" :data="{}"></WEBTREE>
    <WEBROOMHEATCALC v-if="isControlVisible('WEBROOMHEATCALC')" />

    <!-- Shared components inside pages -->
    <WEBLISTCHOOSER
      :data="WEBLISTCHOOSER_DATA"
      :visible="WEBLISTCHOOSER.visible"
      @close-weblistchooser="onCloseWebListChooser"
      @select-weblistchooser-item="onSelectWebListChooserItem"
    ></WEBLISTCHOOSER>

    <WEBOFFCANVAS
      :data="WEBOFFCANVAS_DATA"
      :visible="WEBOFFCANVAS.visible"
      :width="WEBOFFCANVAS.width"
      @close-weboffcanvas="onCloseWeboffcanvas"
    ></WEBOFFCANVAS>

    <WEBPOPLIST
      :data="WEBPOPLIST_DATA"
      :visible="WEBPOPLIST.visible"
      @close-webpoplist="onCloseWebPopList"
      @click-webpoplist-item="onClickWebPopListItem"
    ></WEBPOPLIST>

    <WEBPOPTILECHOOSER
      :data="WEBPOPTILECHOOSER_DATA"
      :visible="WEBPOPTILECHOOSER.visible"
      @close-webpoptilechooser="onCloseWebtileChooser"
      @click-webpoptilechooser-tile="onClickWebPopTileChooser"
    ></WEBPOPTILECHOOSER>
  </div>
</template>

<script>
import { get } from 'lodash';
import mixins from '@/shared/mixins/index';
import WEBLISTCHOOSER from '@/components/Webcontrols/WEBLISTCHOOSER';
import WEBMAINCONFIGU from '@/components/Webcontrols/WEBMAINCONFIGU';
import WEBMIQMA from '@/components/Webcontrols/WEBMIQMA';
import WEBNEWS from '@/components/Webcontrols/WEBNEWS';
import WEBOFFCANVAS from '@/components/Webcontrols/WEBOFFCANVAS';
import WEBPOPLIST from '@/components/Webcontrols/WEBPOPLIST';
import WEBPOPTILECHOOSER from '@/components/Webcontrols/WEBPOPTILECHOOSER';
import WEBROOMHEATCALC from '@/components/Webcontrols/WEBROOMHEATCALC';
import WEBSEARCH from '@/components/Webcontrols/WEBSEARCH';
import WEBSESSION from '@/components/Webcontrols/WEBSESSION';
import WEBTILECHOOSER from '@/components/Webcontrols/WEBTILECHOOSER';
import WEBTREE from '@/components/Webcontrols/WEBTREE';

export default {
  name: 'Pages',

  mixins: [mixins],

  components: {
    WEBLISTCHOOSER,
    WEBMAINCONFIGU,
    WEBMIQMA,
    WEBNEWS,
    WEBOFFCANVAS,
    WEBPOPLIST,
    WEBPOPTILECHOOSER,
    WEBROOMHEATCALC,
    WEBSEARCH,
    WEBSESSION,
    WEBTILECHOOSER,
    WEBTREE
  },

  data() {
    return {
      mainXlevel: 3,
      xLevelCounter: 0
    };
  },

  computed: {
    currentNode() {
      return this.$store.state.node;
    },

    IndicatorBackground() {
      return this.$store.state.pages.indicator.loadingBackground;
    },

    IndicatorIcon() {
      return this.$store.state.pages.indicator.IndicatorIcon;
    },

    IndicatorMessage() {
      return this.$store.state.pages.indicator.Message;
    },

    loading() {
      return this.$store.state.pages.loading;
    },

    visibleControls() {
      return this.$store.state.visibleControls;
    },

    WEBLISTCHOOSER() {
      return this.$store.state.weblistchooser ? this.$store.state.weblistchooser : {};
    },

    WEBLISTCHOOSER_DATA() {
      return this.$store.state.weblistchooser.node ? this.$store.state.weblistchooser.node : {};
    },

    WEBMAINCONFIGU_DATA() {
      return this.$store.state.webmainconfigu.node ? this.$store.state.webmainconfigu.node : {};
    },

    WEBMIQMA_DATA() {
      return this.$store.state.webmiqma.node ? this.$store.state.webmiqma.node : {};
    },

    WEBNEWS_DATA() {
      return this.$store.state.webnews.node ? this.$store.state.webnews.node : {};
    },

    WEBOFFCANVAS() {
      return this.$store.state.weboffcanvas ? this.$store.state.weboffcanvas : {};
    },

    WEBOFFCANVAS_DATA() {
      return this.$store.state.weboffcanvas.node ? this.$store.state.weboffcanvas.node : {};
    },

    WEBPOPLIST() {
      return this.$store.state.webpoplist ? this.$store.state.webpoplist : {};
    },

    WEBPOPLIST_DATA() {
      return this.$store.state.webpoplist.node ? this.$store.state.webpoplist.node : {};
    },

    WEBPOPTILECHOOSER() {
      return this.$store.state.webpoptilechooser ? this.$store.state.webpoptilechooser : {};
    },

    WEBPOPTILECHOOSER_DATA() {
      return this.$store.state.webpoptilechooser.node ? this.$store.state.webpoptilechooser.node : {};
    },

    WEBSEARCH_DATA() {
      return this.$store.state.websearch.node ? this.$store.state.websearch.node : {};
    },

    WEBSESSION_DATA() {
      return this.$store.state.websession.node ? this.$store.state.websession.node : {};
    },

    WEBTILECHOOSER_DATA() {
      return this.$store.state.webtilechooser.node ? this.$store.state.webtilechooser.node : {};
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    /**
     * Show news in dialog
     *
     * @param {Object} response
     * @returns void
     */
    handleCurrentNews(response) {
      const content = get(response, 'data.RESPONSE.content');
      const title = get(response, 'data.RESPONSE.title');

      if (!content) {
        return false;
      }

      this.$store.commit('dialog/OPEN_DIALOG', {
        title: title || this.$t('NEWS'),
        content: response.data.RESPONSE.content
      });
    },

    handlePageChange() {
      this.$store.commit('CLOSE_OFFCANVAS');

      const routeParams = this.$route.params;
      const routeParamId = get(this.$route.params, 'id');
      console.log('Page changed.', routeParams);

      if (this.$store.state.app.sessionNumber === '' || !routeParamId) {
        this.$store.commit('SET_APP_LOADING', { loading: true });

        this.$store.dispatch('GET_FIRST_NODE').then(result => {
          const RESPONSE = get(result, 'data.RESPONSE');
          if (RESPONSE) {
            this.handleMainNavigation(RESPONSE);
            this.handleFirstNode(RESPONSE);
          }
        });
      } else {
        this.$store.commit('pages/SET_PAGES_LOADING', {
          loading: true,
          nodeKey: routeParamId
        });

        this.requestNode(routeParamId)
          .then(result => {
            const RESPONSE = get(result, 'data.RESPONSE');
            if (RESPONSE) {
              this.handleMainNavigation(RESPONSE);
              this.handleNode(RESPONSE);
            }
            this.$store.commit('pages/HIDE_LOADING');
          })
          .catch(() => {
            this.$store.commit('pages/HIDE_LOADING');
          });
      }
    },

    handleItemClick(node, componentName, loadingIndicator = false) {
      const Xnodekey = get(node, 'Xnodekey');
      if (loadingIndicator) {
        this.$store.commit('SET_APP_LOADING', { loading: true });
      }

      // Send current node and refresh data of visible control by sending the specific event
      // to the corresponding component
      this.requestNode(Xnodekey).then(result => {
        const RESPONSE = get(result, 'data.RESPONSE');
        const Xcontroltyp = get(result, 'data.RESPONSE.Xcontroltyp', '');
        const WebLoadNode = get(result, 'data.RESPONSE.WebLoadNode');

        if (loadingIndicator) {
          this.$store.commit('SET_APP_LOADING', { loading: false });
        }

        // Update
        this.refreshVisibleControlData(node);

        // Save
        this.$store.commit(componentName + '/SET_NODE', {
          node: RESPONSE
        });

        if (Xcontroltyp === 'CLEAR' || WebLoadNode) {
          this.handleNode(RESPONSE);
        }
      });
    },

    init() {
      if (this.$session.get('token')) {
        // Set authorization header
        this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('token');
      }

      if (this.$localStorage.get('activeMainNode')) {
        const activeMainNode = JSON.parse(this.$localStorage.get('activeMainNode'));

        this.$store.commit('pages/SET_NODE_MAIN', { node: activeMainNode });
      }
      this.handlePageChange();

      this.requestCurrentNews();
    },

    onClickWebPopListItem(node) {
      console.log('onClickWebPopListItem', node.Xnodekey);
    },

    onCloseWebListChooser() {
      this.$store.commit('weblistchooser/CLOSE');
    },

    onCloseWeboffcanvas() {
      this.$store.commit('weboffcanvas/CLOSE');
    },

    onCloseWebPopList() {
      this.$store.commit('webpoplist/CLOSE');
    },

    onClickWebPopTileChooser(node) {
      const Valid = get(node, 'Valid');
      const Xnodekey = get(node, 'Xnodekey');
      // Prevent refresh if the current node is yet the active one.
      if (!Valid) {
        // Show individual loading indicator for current node
        // this.handleLoadingIndicator(node.Xnodekey);
        this.$store.commit('pages/SET_PAGES_LOADING', {
          loading: true,
          nodeKey: Xnodekey
        });

        // Send current node and refresh data of visible control by sending the specific event
        // to the corresponding component
        this.requestNode(Xnodekey).then(result => {
          const RESPONSE = get(result, 'data.RESPONSE');
          const Xcontroltyp = get(result, 'data.RESPONSE.Xcontroltyp', '');
          const WebLoadNode = get(result, 'data.RESPONSE.WebLoadNode');

          this.$store.commit('pages/HIDE_LOADING');

          // Update
          this.refreshVisibleControlData(node);

          // Save
          this.$store.commit('webpoptilechooser/SET_NODE', {
            node: RESPONSE
          });

          if (Xcontroltyp === 'CLEAR' || WebLoadNode) {
            this.handleNode(RESPONSE);
          }
        });
      }

      this.$store.commit('webpoptilechooser/CLOSE');
    },

    onCloseWebtileChooser() {
      this.$store.commit('webpoptilechooser/CLOSE');
    },

    onSelectWebListChooserItem(node) {
      this.handleItemClick(node, 'weblistchooser', true);

      this.onCloseWebListChooser();
    },

    refreshVisibleControlData(node = {}) {
      const componentName = this.visibleControls[0] ? this.visibleControls[0].toLowerCase() : false;

      if (componentName) {
        this.$events.$emit(`${componentName}:refresh:child`, node);
      }
    },

    /**
     * Request current news to show after login/page refresh
     *
     * @returns Promise
     */
    requestCurrentNews() {
      return this.$store.dispatch('webnews/GET_NEWS_CURRENT').then(response => {
        this.handleCurrentNews(response);
        return response;
      });
    }
  },

  watch: {
    $route(to, from) {
      console.log('Pages route changed', to, from, to.params.id);
      this.handlePageChange();
    }
  }
};
</script>

<style lang="scss"></style>
