<template>
  <el-dialog :title="data.Lanqkey01" :visible="visible" :fullscreen="isFullscreen" :width="dialogWidth" @close="onClose">
    <ul class="tiles" v-loading="loading">
      <Tile :data="child" v-for="child in data.GuiNodeCh" :key="child.Xnodekey" @click-tile="onClickTile"></Tile>
    </ul>
  </el-dialog>
</template>

<script>
import * as Browser from '@/services/browser';
import Tile from '@/components/Tile';
import mixins from '@/shared/mixins/index';

export default {
  name: 'WEBPOPTILECHOOSER',

  components: {
    Tile: Tile
  },

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    },
    visible: {
      type: Boolean,
      required: true
    },
    width: {
      type: String,
      default: '70%'
    }
  },

  data() {
    return {
      dialogWidth: this.width,
      isFullscreen: this.fullscreen
    };
  },

  methods: {
    handleDialogVariation() {
      let dialogWidth = this.width;
      let fullscreen = this.fullscreen;

      if (Browser.isDeviceXS() || Browser.isDeviceSM()) {
        dialogWidth = '100%';
        fullscreen = true;
      }

      this.dialogWidth = dialogWidth;
      this.isFullscreen = fullscreen;
    },

    onClose() {
      // Send event to parent
      this.$emit('close-webpoptilechooser');
    },

    onClickTile(node) {
      // Send event to parent
      this.$emit('click-webpoptilechooser-tile', node);
    }
  },

  watch: {
    visible() {
      this.handleDialogVariation();
    }
  }
};
</script>

<style lang="scss"></style>
