<!--
	@component:	MaschineInfo
	@desc:		This component display machine image and some additional informations.
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2018-08-22
 -->
<template>
  <div class="maschine-info">
    <div class="maschine-info__image-container" v-loading="!img">
      <a v-if="img" href="#" @click.prevent="onClickImage" class="maschine-info__image-link">
        <img :src="getImageHandlerSrc(img, { width: width, maxheight: maxHeight })" class="maschine-info__image" />
      </a>
      <i v-else class="fa fa-photo fa-2x color-gray"></i>
    </div>
    <div class="maschine-info__meta">
      <dl v-for="(info, index) in data.ElemetsRs" :key="index">
        <dt v-html="info.EleTitle" :style="{ 'background-color': info.EleColor }"></dt>
        <dd v-html="info.EleValue" :class="{ 'maschine-info__meta-data--highlight': info.EleDefault === 'N' }"></dd>
      </dl>
    </div>
  </div>
</template>

<script>
import mixins from '@/shared/mixins/index';

export default {
  name: 'MaschineInfo',

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    },
    img: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      maxHeight: 400,
      width: 300
    };
  },

  methods: {
    onClickImage() {
      const imgPath = this.img;
      const src = this.getImageHandlerSrc(imgPath, {
        width: 800,
        maxheight: 600
      });

      const imageTitle = this.data.ElemetsRs.length ? this.data.ElemetsRs[0].EleValue : '';
      this.$store.commit('dialog/OPEN_DIALOG', {
        content: `<img src='${src}' alt='${imageTitle}' />`,
        textCenter: true,
        title: imageTitle,
        width: '50%'
      });
    }
  }
};
</script>

<style lang="scss">
.maschine-info {
  border: 1px solid $c-light-gray;

  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 5rem;
    transition: all 0.3s ease-in-out;
  }

  &__image-link {
    display: block;
    text-align: center;
  }

  &__image {
    max-width: 100%;
    height: auto;
  }
}

.maschine-info__meta {
  &-data {
    &--highlight {
      background-color: transparentize($c-warning, 0.2);
    }
  }

  dt {
    background-color: $c-light-gray;
  }

  dd {
    margin-left: 0;
  }

  dt,
  dd {
    font-family: $font-brand;
    font-size: 0.9rem;
    hyphens: auto;
    padding: 0.1rem 0.2rem;
  }
}

.maschine-info__meta-table {
  border-top: 1px solid $c-light-gray;
  background-color: $c-dark-white;

  td {
    font-size: 0.9rem;

    &:first-child {
      padding-left: 0.3rem;
    }
  }
}
</style>
