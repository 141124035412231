<template>
  <el-dialog :title="data.Lanqkey01" :visible="visible" :fullscreen="fullscreen" :width="width" @close="onClose">
    <el-form ref="form-webpoplist" @submit.native.prevent>
      <ul class="no-padding no-margin">
        <NodeControls
          v-for="item in data.GuiNodeCh"
          :key="item.Xnodekey"
          :data="item"
          :component="item.XcontrolChild"
          :update-node-key="data.Xnodekey"
        ></NodeControls>
      </ul>
    </el-form>
  </el-dialog>
</template>

<script>
import mixins from '@/shared/mixins/index';

export default {
  name: 'WEBPOPLIST',

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    },
    fullscreen: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    visible: {
      type: Boolean,
      required: true
    },
    width: {
      type: String
    }
  },

  methods: {
    onClose() {
      // Send event to parent
      this.$emit('close-webpoplist');
    },

    onClickItem(node) {
      // Send event to parent
      this.$emit('click-webpoplist-item', node);
    }
  }
};
</script>
