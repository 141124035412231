<!--
	@component:	WEBMAINCONFIGU
	@desc:		This component is the main component for the content area and wrap child components to
				display navigation, content and additional informations.
				Make sure to register all available components first to prevent errors.
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2020-03-30
 -->
<template>
  <div class="webmainconfigu">
    <el-row :gutter="20">
      <el-col :sm="24" :md="5" class="main-content__left">
        <!-- Navigation -->
        <WEBMAINCONFIGU_NAV :data="data" @change-page="onChangePage"></WEBMAINCONFIGU_NAV>
      </el-col>

      <el-col :xs="24" :sm="18" :md="14" class="main-content__center">
        <!-- Dynamic components. Make sure to register all components -->
        <component :is="currentComponent" :data="childComponentData" @update-child-data="onUpdateChild"></component>
      </el-col>

      <el-col :xs="24" :sm="6" :md="5" class="main-content__right">
        <MachineInfo :data="MACHINEINFO_DATA" :img="MACHINEINFO_DATA.MachinePicPath"></MachineInfo>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from 'lodash';
import mixins from '@/shared/mixins/index';

const MachineInfo = () => import('@/components/MachineInfo');
const WEBMAINCONFIGU_NAV = () => import('@/components/Webcontrols/WEBMAINCONFIGU_NAV');
const WEBMIQMAELEM = () => import('@/components/Webcontrols/WEBMIQMAELEM');
const WEBHTML = () => import('@/components/Webcontrols/WEBHTML');
const WEBMULTI = () => import('@/components/Webcontrols/WEBMULTI');

export default {
  name: 'WEBMAINCONFIGU',

  mixins: [mixins],

  components: {
    MachineInfo,
    WEBHTML,
    WEBMAINCONFIGU_NAV,
    WEBMIQMAELEM,
    WEBMULTI
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      currentComponent: 'WEBMULTI',
      initalComponent: {
        XcontrolMother: 'WEBMULTI'
      }
    };
  },

  computed: {
    childComponentData() {
      return this.$store.state.webmainconfigu.childComponentData;
    },

    MACHINEINFO_DATA() {
      return this.$store.state.machineinfo.node;
    },

    INITIAL_CHILD_DATA() {
      const component = this.initalComponent.XcontrolMother.toLowerCase();
      return this.$store.state[component].node ? this.$store.state[component].node : {};
    }
  },

  mounted() {
    this.init();
  },

  destroyed() {
    this.unregisterEvents();
    console.log('WEBMAINCONFIGU destroyed.');
  },

  methods: {
    handleWebLoadNode() {
      const componentToLoad = this.data.WebLoadNode;
      this.initalComponent = this.data.GuiNodeCh[componentToLoad];
      this.currentComponent = this.initalComponent.XcontrolMother;
      this.$store.commit('webmainconfigu/SET_CHILD_COMPONENT_DATA', this.initalComponent);
    },

    init() {
      // Should we need to load some more nodes
      if (this.data.WebLoadNode.length) {
        this.handleWebLoadNode();
      }

      this.registerEvents();

      // Get info for current machine
      this.$store.dispatch('machineinfo/GET_MACHINE_INFO');
    },

    onChangePage(data) {
      const XcontrolMother = get(data, 'XcontrolMother', '');

      if (this.isComponentRegistered(XcontrolMother)) {
        // Replace the current node with data inside the component
        // so we can search for loading indicators for the clicked nav item
        this.$store.commit('SET_NODE', this.data);

        // Reset stored data for the main column
        this.$store.commit('webmainconfigu/SET_CHILD_COMPONENT_DATA', {});

        this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: data.Xnodekey });
        this.currentComponent = XcontrolMother;

        this.requestNode(data.Xnodekey).then(result => {
          this.$store.commit('pages/HIDE_LOADING');
          const RESPONSE = get(result, 'data.RESPONSE');
          if (RESPONSE) {
            this.$store.commit('webmainconfigu/SET_CHILD_COMPONENT_DATA', RESPONSE);
          }
        });

        // Get info for current machine
        this.$store.dispatch('machineinfo/GET_MACHINE_INFO');
      } else {
        console.log('component not registered', XcontrolMother);
      }
    },

    onUpdateChild(node) {
      this.$store.commit('webmainconfigu/SET_CHILD_COMPONENT_DATA', node);
    },

    registerEvents() {
      this.$events.$on('webmainconfigu:refresh:child', node => {
        this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: node.Xnodekey });

        this.requestNode(this.childComponentData.Xnodekey).then(result => {
          const RESPONSE = get(result, 'data.RESPONSE');
          if (RESPONSE) {
            this.onUpdateChild(RESPONSE);
          }
          this.$store.commit('pages/HIDE_LOADING');
        });

        // Update machine info on right side
        this.$store.dispatch('machineinfo/GET_MACHINE_INFO');
      });
    },

    unregisterEvents() {
      this.$events.$off('webmainconfigu:refresh:child');
    }
  },

  watch: {
    INITIAL_CHILD_DATA(newValue) {
      this.$store.commit('webmainconfigu/SET_CHILD_COMPONENT_DATA', newValue);
    }
  }
};
</script>
