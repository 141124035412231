<template>
  <ul class="tiles">
    <Tile :data="child" v-for="child in data.GuiNodeCh" :key="child.Xnodekey"></Tile>
  </ul>
</template>

<script>
import Tile from '@/components/Tile';

export default {
  name: 'WEBTILECHOOSER',

  components: {
    Tile: Tile
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {};
  },

  mounted() {
    this.init();
    console.log('WEBTILECHOOSER mounted...', this.data);
  },

  destroyed() {
    console.log('WEBTILECHOOSER destroyed.');
  },

  methods: {
    init() {
      this.$store.commit('webtilechooser/SET_NODE', { node: this.data });
    }
  }
};
</script>

<style lang="scss"></style>
