<template>
  <div class="websearch p-t-2">
    <el-row>
      <el-col :xs="24" :sm="{ span: 12, offset: 6 }" :md="{ span: 10, offset: 7 }" class="m-b-2">
        <div class="flex--sm">
          <el-input :placeholder="$t('INPUT_SEARCHSTRING')" v-model="searchString" clearable suffix-icon="el-icon-search"></el-input>
        </div>
      </el-col>

      <el-col :span="24">
        <Table
          v-if="node.GridHeaderInfo.length"
          :data="node"
          :action-icon-class="'el-icon-arrow-right'"
          v-on:action-click="onActionClick"
        ></Table>

        <el-pagination
          v-if="totalItems"
          background
          layout="prev, pager, next"
          :total="totalItems"
          :page-size="pageSize"
          @current-change="onChangePage"
          class="text-center m-t-2 m-b-2"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from 'lodash';
import mixins from '@/shared/mixins/index';
import tableMixin from '@/shared/mixins/table';
import Table from '@/components/Table';

export default {
  name: 'WEBSEARCH',

  components: {
    Table: Table
  },

  mixins: [mixins, tableMixin],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      minSearchStringCount: 2,
      searchString: ''
    };
  },

  computed: {
    currentPage() {
      return this.$store.state.websearch.pagination.currentPage;
    },

    node() {
      return this.$store.state.websearch.node;
    },

    pageSize() {
      return this.$store.state.websearch.pagination.pageSize;
    },

    pageSizes() {
      return this.$store.state.websearch.pagination.pageSizes;
    },

    totalItems() {
      return this.$store.state.websearch.totalItems;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      console.log('WEBSEARCH mounted...');
      this.onClickSearchButton();
    },

    /**
     * Before the table can be initialize it is neccesary to add new columns
     * and save in the store.
     */
    initTable(data) {
      let node = _.cloneDeep(data);

      // Add image column
      if (node.GridHeaderInfo[1].Fieldname !== 'IMAGE') {
        node.GridHeaderInfo.unshift(this.createImageColumn());
      }

      // Add info column
      if (node.GridHeaderInfo[0].Fieldname !== 'INFO') {
        node.GridHeaderInfo.unshift(this.createInfoColumn());
      }

      // Save to store
      this.$store.commit('websearch/SET_NODE', { node: node });
    },

    onActionClick(data) {
      this.$router.push({ path: '/pages/' + data.node.Xnodekey });
    },

    onChangePage(page) {
      this.$store.commit('websearch/SET_PAGINATION', {
        pageSize: this.pageSize,
        currentPage: page
      });

      this.onSearchInput();
    },

    onClickSearchButton() {
      this.resetPagination();
      this.onSearchInput();
    },

    onSearchInput() {
      const string = this.searchString.toString();
      this.$store.commit('pages/SET_PAGES_LOADING', { loading: true });

      this.$store
        .dispatch('websearch/GET_MACHINES', {
          PAGELIMIT: this.pageSize,
          PAGEOFFSET: this.currentPage,
          SEARCHSTRING: string
        })
        .then(response => {
          this.initTable(response.data.RESPONSE);

          this.$store.commit('pages/HIDE_LOADING');
        })
        .catch(() => {
          this.$store.commit('pages/HIDE_LOADING');
        });
    },

    resetPagination() {
      this.$store.commit('websearch/RESET_PAGINATION');
    }
  },

  watch: {
    searchString(newValue, oldValue) {
      // Do nothing if value is the same
      if (newValue === oldValue) {
        return;
      }

      // Reset table data if searchString is empty
      if (newValue === '') {
        this.resetPagination();

        this.loading = true;
        setTimeout(() => {
          this.onSearchInput();
          this.loading = false;
        }, 400);

        return;
      }

      if (newValue.length + 1 <= this.minSearchStringCount) {
        return;
      }

      this.onSearchInput();
      // this.loading = true;
      // setTimeout(() => {
      // this.onSearchInput();
      // 	this.loading = false;
      // }, 400);
    }
  },

  destroyed() {
    console.log('WEBSEARCH destroyed.');
  }
};
</script>

<style lang="scss"></style>
