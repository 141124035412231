<!--
	@component:	WEBOFFCANVAS
	@desc:		This component is used to show dynamic components defined in the service tool inside off the offcanvas.
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2019-01-18
 -->
<template>
  <aside class="offcanvas weboffcanvas" :class="['offcanvas--' + alignment, { 'is-visible': visible }]" :style="'width:' + width">
    <header class="weboffcanvas__header">
      <h3 class="weboffcanvas__headline">{{ Lanqkey01 }}</h3>

      <!-- Toogle Button -->
      <el-button type="text" class="btn-offcanvas" @click.prevent="close">
        <div class="nav-icon nav-icon--cross">
          <span class="nav-icon__stripe"></span>
          <span class="nav-icon__stripe"></span>
        </div>
      </el-button>
    </header>

    <main class="weboffcanvas__body">
      <!-- Dynamic components goes here -->
      <component v-if="component && visible" :is="loadComponent" :data="data"></component>
      <p class="text-center font-brand" v-else>{{ $t('NO_DATA_AVAILABLE') }}</p>
    </main>
  </aside>
</template>

<script>
export default {
  name: 'WEBOFFCANVAS',

  props: {
    alignment: {
      type: String,
      required: false,
      default: 'right'
    },
    data: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: '30%'
    }
  },

  computed: {
    component() {
      return this.data.XcontrolChild || null;
    },

    loader() {
      if (!this.component) {
        return null;
      }
      return () => import(`@/components/Webcontrols/${this.component}`);
    },

    Lanqkey01() {
      return this.data.Lanqkey01 || '';
    }
  },

  data() {
    return {
      loadComponent: null
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.loader()
        .then(() => {
          this.loadComponent = () => this.loader();
        })
        .catch(() => {
          this.loadComponent = null; //() => import('./ANZTEXT')
        });
    },

    close() {
      this.$emit('close-weboffcanvas');
    }
  }
};
</script>

<style lang="scss">
.weboffcanvas {
  box-shadow: 0 0 18px 0 transparentize($c-black, 0.6);
  transition: all 0.2s ease-in-out;
  min-height: 100%;
  height: auto;

  @media screen and (min-width: $screen-md) {
    min-width: 30rem;
  }

  &__body {
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
  }

  &__headline {
    background-color: $c-extra-light-gray;
    line-height: 1.5rem;
    margin: 0;
    padding: 1.2rem 1rem;
  }
}
</style>
