<template>
  <div>
    <el-table
      :data="gridData"
      border
      style="width: 100%"
      v-loading="loading"
      :default-sort="{ prop: 'Datum', order: 'descending' }"
      :row-style="getRowStyle"
      :row-class-name="getRowClass"
      :empty-text="$t('NO_DATA_AVAILABLE')"
      @row-click="onRowClick"
    >
      <el-table-column
        v-for="(header, index) in gridHeaderInfo"
        :key="index"
        :label="header.Title"
        :sortable="header.Sortable"
        :sort-method="onSort"
        :header-align="header.Align === 'R' ? 'right' : 'left'"
        :align="getAlignment(header)"
        :min-width="header.Width"
        :index="index"
      >
        <template slot-scope="scope">
          <span v-if="header.Fieldname === 'ACTION'">
            <!-- Show report for expired sessions or the session itself for valid sessions -->
            <a
              v-if="scope.row.node.SessionShow === 'TDPDF'"
              :href="getReportHandlerUrl(scope.row.node.Sessionnumber, scope.row.node.SessionShow)"
              :title="scope.row.node.Sessionnumber"
              class="el-button el-button--primary"
              target="_blank"
            >
              <i class="el-icon-document"></i>
            </a>
            <el-button v-else type="primary" @click="onActionClick(scope.row)">
              <i :class="actionIconClass"></i>
            </el-button>
          </span>

          <span v-else-if="header.Fieldname === 'IMAGE'">
            <a href="#" @click.prevent="onImageClick(scope.row)">
              <img :src="getImageHandlerSrc(scope.row.node.ImageOnPath, { width: 70 })" :alt="scope.row.node.Lanqkey01" />
            </a>
          </span>

          <span v-else-if="header.Fieldname === 'INFO' && scope.row.node.InfoTooltipNumber">
            <el-button @click="onInfoClick(scope.row)" icon="el-icon-info" type="text" class="el-icon--big"></el-button>
          </span>

          <!-- Regular content -->
          <div v-else v-html="scope.row[header.Title]" class="cell__content"></div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { find, forEach, get } from 'lodash';
import mixins from '@/shared/mixins/index';

export default {
  name: 'Table',

  mixins: [mixins],

  props: {
    actionIconClass: {
      type: String,
      default: 'el-icon-plus'
    },
    data: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean
    },
    initActiveRowIndex: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      clickedRow: null,
      initActiveRowIndexCurrent: null,
      gridData: [],
      gridHeaderInfo: []
    };
  },

  mounted() {
    this.init();
    console.log('Table mounted', this.data);
  },

  methods: {
    createLastColumn() {
      return {
        Fieldname: 'ACTION',
        Title: '',
        Width: '80',
        Align: 'R',
        Sortable: false
      };
    },

    init() {
      this.onRefresh();
      this.initActiveRowIndexCurrent = this.initActiveRowIndex;
    },

    generateGridData(node) {
      const GuiNodeCh = get(node, 'GuiNodeCh');
      const GridHeaderInfo = get(this.data, 'GridHeaderInfo');
      const gridData = [];

      forEach(GuiNodeCh, child => {
        const obj = {};
        forEach(child.GridColumnInfo, (value, key) => {
          const header = find(GridHeaderInfo, { Fieldname: key });
          key = header.Title;
          obj[key] = value;
        });
        obj.node = child;
        gridData.push(obj);
      });

      return gridData;
    },

    getAlignment(header) {
      const Align = get(header, 'Align', '');

      if (Align === 'R') {
        return 'right';
      }
      if (Align === 'C') {
        return 'center';
      }
      return 'left';
    },

    getRowClass({ row, rowIndex }) {
      if (row === this.clickedRow) {
        this.initActiveRowIndexCurrent = null;
        return 'el-table__row--highlight';
      }
      if (this.initActiveRowIndexCurrent === rowIndex) {
        return 'el-table__row--highlight';
      }
      return '';
    },

    getRowStyle({ row }) {
      const ColorShow = get(row, 'node.ColorShow', '');
      const MIQVzModell = get(row, 'node.MIQVzModell', '');
      let bgColor = '';

      if (ColorShow) {
        bgColor = ColorShow;
      }

      if (MIQVzModell) {
        bgColor = MIQVzModell;
      }

      return {
        'background-color': bgColor
      };
    },

    onActionClick(data) {
      this.$emit('action-click', data);
    },

    onImageClick(data) {
      const ImageOnPath = get(data, 'node.ImageOnPath', '');
      const Lanqkey01 = get(data, 'node.Lanqkey01', '');
      const imageSrc = this.getImageHandlerStyleSrc(ImageOnPath, { width: 500 });

      this.$store.commit('dialog/OPEN_DIALOG', {
        content: `<img src="${imageSrc}" alt="${Lanqkey01}">`,
        visible: true,
        textCenter: true,
        width: '30%'
      });
    },

    onInfoClick(data) {
      this.$store.commit('dialog/OPEN_DIALOG', {
        loading: true
      });

      this.$store
        .dispatch('GET_TOOLTIP', data.node)
        .then(response => {
          const responseData = get(response, 'data.RESPONSE', {});
          this.$store.commit('dialog/OPEN_DIALOG', {
            content: responseData,
            loading: false
          });
        })
        .catch(() => {
          this.$store.commit('dialog/CLOSE_DIALOG');
        });
    },

    onRefresh() {
      this.gridHeaderInfo = get(this.data, 'GridHeaderInfo', []);

      if (this.gridHeaderInfo.length) {
        this.gridHeaderInfo.forEach(headerInfo => {
          if (headerInfo.Sortable === undefined) {
            headerInfo.Sortable = false;
          }
        });

        // Add last column only if not available
        const lastFieldname = get(this.gridHeaderInfo[this.gridHeaderInfo.length - 1], 'Fieldname', '');
        if (lastFieldname !== 'ACTION') {
          this.gridHeaderInfo.push(this.createLastColumn());
        }
      }

      this.gridData = this.generateGridData(this.data);
    },

    onRowClick(row, column, event) {
      const nodeName = get(event, 'target.nodeName', '');
      const isClickOnCell = nodeName === 'TD' || nodeName === 'DIV' || nodeName === 'SPAN';

      if (isClickOnCell) {
        this.$emit('row-click', row.node);
        this.clickedRow = row;
      }
    },

    onSort(row, index) {
      console.log('onSort', row, index);
    }
  },

  watch: {
    data() {
      this.onRefresh();
    }
  }
};
</script>
