<!--
	@component:	WEBMIQMA
	@desc:		This component shows a list of machines and some additional informations.
	@author: 	Yannick Herzog, info@hit-services.net
	@modified:	2018-08-07
 -->
<template>
  <div class="webmiqma">
    <el-row :gutter="20">
      <!-- Left Column -->
      <el-col :xs="24" :sm="20" class="main-content__center">
        <Table
          :data="data"
          :action-icon-class="'el-icon-arrow-right'"
          :init-active-row-index="selectedRowIndex"
          v-on:action-click="onActionClick"
          v-on:row-click="onRowClick"
        />
      </el-col>

      <!-- Right Column -->
      <el-col :xs="24" :sm="4" class="main-content__right">
        <MachineInfo :data="selectedRow" :img="selectedRowImg" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { cloneDeep, get } from 'lodash';
import MachineInfo from '@/components/MachineInfo';
import Table from '@/components/Table';
import tableMixin from '@/shared/mixins/table';

export default {
  name: 'WEBMIQMA',

  components: {
    MachineInfo,
    Table
  },

  mixins: [tableMixin],

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selectedRow: {},
      selectedRowImg: '',
      selectedRowIndex: 0
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      let node = cloneDeep(this.data);
      const GridHeaderInfo = get(node, 'GridHeaderInfo', []);
      const Fieldname = get(GridHeaderInfo[0], 'Fieldname', '');
      const GuiNodeCh = get(node, 'GuiNodeCh');

      // Add info column
      if (Fieldname !== 'INFO') {
        node.GridHeaderInfo.unshift(this.createInfoColumn());
      }

      this.$store.commit('webmiqma/SET_NODE', { node: node });

      // Get info for first machine
      const FIRST_MACHINE_NODE_NAME = Object.keys(GuiNodeCh)[0];
      this.onRowClick(this.data.GuiNodeCh[FIRST_MACHINE_NODE_NAME]);
    },

    onActionClick(data) {
      const Xnodekey = get(data, 'node.Xnodekey');
      if (Xnodekey) {
        this.$router.push({ path: '/pages/' + Xnodekey });
      }
    },

    onRowClick(data) {
      const ImageOnPath = get(data, 'ImageOnPath');
      const ImageOnPathSelf = get(data, 'ImageOnPathSelf');
      const MachineInfo = get(data, 'MachineInfo', '');

      this.selectedRow = {
        ElemetsRs: cloneDeep(MachineInfo)
      };
      this.selectedRowImg = ImageOnPath || ImageOnPathSelf;
    }
  }
};
</script>
