<template>
  <div class="webroomheatcalc">
    <!-- Headline -->
    <div class="flex valign-center space-between">
      <h2>{{ $t('ROOM_HEAT_CALCULATION') }}</h2>
      <div>
        <el-switch v-model="isExportMode" :active-text="$t('EXPERT_MODE')" :inactive-text="$t('NORMAL_MODE')"> </el-switch>
      </div>
    </div>
    <!-- 1. Row -->
    <el-row :gutter="20" class="m-t-1">
      <el-col :xs="24" :md="16">
        <!-- Tabs -->
        <el-tabs v-model="actsection" type="card">
          <!-- Machine -->
          <el-tab-pane :label="$t('MACHINE')" name="0">
            <el-select v-model="machine.selected" :placeholder="$t('SELECT_MACHINE')" clearable filterable class="w-100">
              <el-option-group v-for="(group, i) in filteredMachinesGroupByModule" :key="i" :label="group.label">
                <el-option v-for="(item, index) in group.machines" :key="index" :label="item.DisplayName" :value="item.NodeId"> </el-option>
              </el-option-group>
            </el-select>

            <el-checkbox-group v-model="filteredMachineGroupNames" class="m-t-1">
              <el-checkbox :label="group.label" v-for="(group, index) in machinesGroupByModule" :key="index"></el-checkbox>
            </el-checkbox-group>

            <div class="text-right m-t-1">
              <el-button type="text" :disabled="!selectedMachine" @click="onClickMachineCalc(selectedMachine)">{{
                $t('CALCULATE')
              }}</el-button>
              <el-button type="primary" :disabled="!selectedMachine" @click="onClickAddMachine(selectedMachine)">{{
                $t('ACQUIRE')
              }}</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('CONFIGURED_MACHINES')" name="1">
            <el-select v-model="machineConfigured.selected" :placeholder="$t('SELECT_MACHINE')" clearable filterable class="w-100">
              <el-option-group v-for="(group, index) in filteredConfiguredMachinesGroupByModule" :key="index" :label="group.label">
                <el-option v-for="(item, i) in group.machines" :key="i" :label="item.label" :value="item.value"> </el-option>
              </el-option-group>
            </el-select>

            <el-checkbox-group v-model="filteredMachineGroupNames" class="m-t-1">
              <el-checkbox :label="group.label" v-for="(group, index) in machinesConfiguredGroupByModule" :key="index"></el-checkbox>
            </el-checkbox-group>

            <div class="text-right m-t-1">
              <el-button type="text" :disabled="!selectedMachineConfigured" @click="onClickMachineCalc(selectedMachineConfigured)">{{
                $t('CALCULATE')
              }}</el-button>
              <el-button type="primary" :disabled="!selectedMachineConfigured" @click="onClickAddMachine(selectedMachineConfigured)">{{
                $t('ACQUIRE')
              }}</el-button>
            </div>
          </el-tab-pane>
          <!-- Dishes -->
          <el-tab-pane :label="$t('DISHES')" name="2">
            <el-form :model="dish" :rules="dishRules" ref="dishForm">
              <el-row :gutter="20">
                <el-col :xs="24" :sm="8">
                  <el-form-item :label="$t('DESCRIPTION')" prop="DisplayName">
                    <el-input v-model="dish.DisplayName" />
                  </el-form-item>

                  <el-form-item :label="$t('ROOM_TEMPERATURE')" prop="RoomTemperature" v-if="isExportMode">
                    <el-input v-model.number="dish.RoomTemperature" type="number" step="any">
                      <template slot="append">°C</template>
                    </el-input>
                  </el-form-item>

                  <el-form-item :label="$t('UNLOAD_CORE_TEMPERATURE')" prop="UnloadCoreTemperature" v-if="isExportMode">
                    <el-input v-model.number="dish.UnloadCoreTemperature" type="number" step="any">
                      <template slot="append">°C</template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="8">
                  <el-form-item :label="$t('UNITS_PER_HOURS')" prop="UnitsPerHour">
                    <el-input v-model.number="dish.UnitsPerHour" type="number" step="any">
                      <template slot="append">{{ $t('UNITS_PER_HOUR') }}</template>
                    </el-input>
                  </el-form-item>

                  <el-form-item :label="$t('MASS_PER_UNIT')" prop="MassPerUnit" v-if="isExportMode">
                    <el-input v-model.number="dish.MassPerUnit" type="number" step="any">
                      <template slot="append">{{ $t('KILOGRAMM_PER_UNIT') }}</template>
                    </el-input>
                  </el-form-item>

                  <el-form-item :label="$t('PERCENTAGE_STAYING_IN_ROOM')" prop="PercentageStayingInRoom" v-if="isExportMode">
                    <el-input v-model.number="dish.PercentageStayingInRoom" type="number">
                      <template slot="append">%</template>
                    </el-input>
                  </el-form-item>
                </el-col>

                <el-col :xs="24" :sm="8">
                  <el-form-item :label="$t('EFFECTIVE_HEAT_TRANSFER')" prop="EffectiveHeatTransfer" v-if="isExportMode">
                    <el-input v-model.number="dish.EffectiveHeatTransfer" type="number" step="any">
                      <template slot="append">%</template>
                    </el-input>
                  </el-form-item>

                  <el-form-item :label="$t('REMAINING_WATER_PER_UNIT')" prop="RemainingWaterPerUnit" v-if="isExportMode">
                    <el-input v-model.number="dish.RemainingWaterPerUnit" type="number" step="any">
                      <template slot="append">{{ $t('GRAMM_PER_UNIT') }}</template>
                    </el-input>
                  </el-form-item>

                  <el-form-item :label="$t('MATERIAL')" prop="MaterialId">
                    <el-select v-model="dish.MaterialId" :placeholder="$t('SELECT_MATERIAL')" class="w-100">
                      <el-option v-for="item in dishMaterials" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>

            <!-- Alert -->
            <el-alert v-if="alert.visible" class="m-t-1 m-b-2" :title="alert.title" :type="alert.type" show-icon>
              <template>
                <div v-html="alert.description"></div>
              </template>
            </el-alert>

            <div class="flex space-between">
              <el-button type="text" @click="onClickOpenDishLibrary">{{ $t('SELECT_FROM_LIBRARY') }}</el-button>

              <div class="text-right">
                <el-button type="text" @click="onClickDishCalc">{{ $t('CALCULATE') }}</el-button>
                <el-button type="primary" @click="onClickAddDish">{{ $t('ACQUIRE') }}</el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>

        <el-alert class="m-t-1" :title="$t('HINT.CONVEYOR_SPEED')" type="info" show-icon :closable="false"> </el-alert>
      </el-col>

      <el-col :xs="24" :md="8" style="height: 100%">
        <div class="container__item" style="height: 100%">
          <div class="flex space-between a-center b-b p-t-half p-r-1 p-b-half p-l-1">
            <h3 class="font--regular font--w-700 m-t-half m-b-half">{{ $t('HEAT_FLOW') }}</h3>

            <div class="text-right">
              <el-button type="text" @click="resetResults">{{ $t('RESET') }}</el-button>
            </div>
          </div>

          <div class="flex flex-column p-1" v-loading="isCalculateResult">
            <div class="flex valign-center">
              <el-input v-model="result.Total" class="w-50" style="max-width: 5rem" />
              <span class="m-l-half color-dark">{{ $t('KW_BEFORE') }}</span>
            </div>
            <div class="flex valign-center m-t-half">
              <el-input v-model="result.Sensible" class="w-50" style="max-width: 5rem" />
              <span class="m-l-half color-dark">{{ $t('KW_SENSIBLE') }}</span>
            </div>
            <div class="flex valign-center m-t-half">
              <el-input v-model="result.Latent" class="w-50" style="max-width: 5rem" />
              <span class="m-l-half color-dark">{{ $t('KW_LATENT') }}</span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 2. Row -->
    <el-row class="m-t-1">
      <el-col :xs="24">
        <h3>{{ $t('COMPILATION') }}</h3>
      </el-col>
      <el-col :xs="24">
        <!-- Table -->
        <el-table :data="compilation" show-summary :summary-method="getSummaries" :empty-text="$t('NO_DATA_AVAILABLE')">
          <el-table-column :label="$t('NAME')" prop="DisplayName">
            <template slot-scope="scope">{{ scope.row.DisplayName }}</template>
          </el-table-column>
          <el-table-column :label="$t('UNITS_PER_HOUR')" prop="UnitsPerHour">
            <template slot-scope="scope">
              <span v-if="scope.row.UnitsPerHour">
                {{ scope.row.UnitsPerHour }}
              </span>
              <span v-else>&mdash;</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('MASS_PER_UNIT')" prop="MassPerUnit" v-if="isExportMode">
            <template slot-scope="scope">
              <span v-if="scope.row.MassPerUnit"> {{ scope.row.MassPerUnit }}kg </span>
              <span v-else>&mdash;</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('PERCENTAGE_STAYING_IN_ROOM')" prop="PercentageStayingInRoom" v-if="isExportMode">
            <template slot-scope="scope">
              <span v-if="scope.row.PercentageStayingInRoom"> {{ scope.row.PercentageStayingInRoom }}% </span>
              <span v-else>&mdash;</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('EFFECTIVE_HEAT_TRANSFER')" prop="EffectiveHeatTransfer" v-if="isExportMode">
            <template slot-scope="scope">
              <span v-if="scope.row.EffectiveHeatTransfer"> {{ scope.row.EffectiveHeatTransfer }}% </span>
              <span v-else>&mdash;</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('MATERIAL')" prop="MaterialId">
            <template slot-scope="scope">
              <span v-if="scope.row.MaterialId">
                {{ getDishMaterialById(scope.row.MaterialId).DisplayName }}
              </span>
              <span v-else>&mdash;</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('REMAINING_WATER_PER_UNIT')" prop="RemainingWaterPerUnit" v-if="isExportMode">
            <template slot-scope="scope">
              <span v-if="scope.row.RemainingWaterPerUnit >= 0"> {{ scope.row.RemainingWaterPerUnit }}g </span>
              <span v-else>&mdash;</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('ROOM_TEMPERATURE')" prop="RoomTemperature" v-if="isExportMode">
            <template slot-scope="scope">
              <span v-if="scope.row.RoomTemperature"> {{ scope.row.UnloadCoreTemperature }} - {{ scope.row.RoomTemperature }} °C </span>
              <span v-else>&mdash;</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('SENSIBLE')" prop="Sensible">
            <template slot-scope="scope"> {{ scope.row.Sensible }} kW </template>
          </el-table-column>
          <el-table-column :label="$t('LATENT')" prop="Latent">
            <template slot-scope="scope"> {{ scope.row.Latent }} kW </template>
          </el-table-column>
          <el-table-column :label="$t('TOTAL')" prop="Total">
            <template slot-scope="scope"> {{ scope.row.Total }} kW </template>
          </el-table-column>
          <el-table-column width="80">
            <template slot-scope="scope">
              <el-button type="primary" plain :title="$t('REMOVE')" @click="onClickDeleteItemInCompilation(scope.$index)">
                <i class="fa fa-trash"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { forEach, get, sortBy } from 'lodash';
import { MODULE_NAME as DIALOG_MODULE } from '@/store/modules/dialog';
import { MODULE_NAME as WEBROOMHEATCALC_MODULE } from '@/store/modules/webroomheatcalc';
import * as HeatFlowDataService from '@/services/heatFlowData';

export default {
  name: 'WEBROOMHEATCALC',

  computed: {
    ...mapGetters({
      dishes: `${WEBROOMHEATCALC_MODULE}/allDishes`,
      dishesGroupByCategory: `${WEBROOMHEATCALC_MODULE}/dishesGroupByCategory`,
      dishCategories: `${WEBROOMHEATCALC_MODULE}/allDishCategories`,
      dishMaterials: `${WEBROOMHEATCALC_MODULE}/allDishMaterials`,
      machinesGroupByModule: `${WEBROOMHEATCALC_MODULE}/machinesGroupByModule`,
      machinesConfiguredGroupByModule: `${WEBROOMHEATCALC_MODULE}/machinesConfiguredGroupByModule`,
      isLoadingMachines: `${WEBROOMHEATCALC_MODULE}/isLoadingMachines`
    }),
    filteredMachinesGroupByModule() {
      let result = this.machinesGroupByModule.slice();
      if (this.filteredMachineGroupNames.length) {
        result = this.getFilteredMachineGroupsByGroupName(this.machinesGroupByModule, this.filteredMachineGroupNames);
      }
      return sortBy(result, ['label']);
    },
    filteredConfiguredMachinesGroupByModule() {
      let result = this.machinesConfiguredGroupByModule.slice();
      if (this.filteredMachineGroupNames.length) {
        result = this.getFilteredMachineGroupsByGroupName(this.machinesConfiguredGroupByModule, this.filteredMachineGroupNames);
      }
      return sortBy(result, ['label']);
    },
    isDishSectionDisabled() {
      return this.actsection !== 1;
    },
    isMachineSectionDisabled() {
      return this.actsection !== 0;
    },
    selectedMachine() {
      let result = null;

      if (this.machine.selected) {
        result = this.$store.getters[`${WEBROOMHEATCALC_MODULE}/machineById`](this.machine.selected);
      }
      return result;
    },
    selectedMachineConfigured() {
      let result = null;

      if (this.machineConfigured.selected) {
        result = this.$store.getters[`${WEBROOMHEATCALC_MODULE}/machineConfiguredById`](this.machineConfigured.selected);
      }
      return result;
    }
  },

  data() {
    return {
      alert: {
        type: 'error',
        title: '',
        description: '',
        visible: false
      },
      actsection: 0,
      compilation: [],
      dish: {
        DisplayName: '',
        RoomTemperature: 26,
        UnloadCoreTemperature: 50,
        UnitsPerHour: 2000,
        MassPerUnit: 0.7,
        PercentageStayingInRoom: 100,
        EffectiveHeatTransfer: 100,
        RemainingWaterPerUnit: 0,
        MaterialId: 1,
        Image: null
      },
      dishRules: {
        UnitsPerHour: [{ required: false, message: this.$t('PLEASE_FILL_OUT_MANDATORY_FIELDS'), trigger: 'blur' }],
        MaterialId: [{ required: true, message: this.$t('PLEASE_FILL_OUT_MANDATORY_FIELDS'), trigger: 'blur' }]
      },
      filteredMachineGroupNames: [],
      isDishFormValid: false,
      machine: {
        selected: null
      },
      machineConfigured: {
        selected: null
      },
      result: {
        Total: null,
        Sensible: null,
        Latent: null
      },
      isCalculateResult: false,
      isExportMode: false
    };
  },

  mounted() {
    this.init();
    this.registerEvents();
    this.isDishFormValid = this.isFormValid('dishForm');
  },

  methods: {
    async calculateDish() {
      try {
        this.isCalculateResult = true;
        const response = await HeatFlowDataService.calculate(this.dish);
        const { data } = response;
        this.resetAlert();

        return data;
      } catch (error) {
        // Handle error messages from backend
        const errorMessage = get(error, 'response.data.Message');
        const ModelState = get(error, 'response.data.ModelState');

        if (errorMessage) {
          this.alert.visible = true;
          this.alert.description = errorMessage;
          this.alert.title = this.$t('ERROR');
        }

        // If validation errors from backend are available
        if (ModelState) {
          const errors = [];
          forEach(ModelState, item => {
            if (item.length) {
              errors.push(...item);
            }
          });

          if (errors.length) {
            // Create list of error messages as alert description
            let errorMessages = errors.join(', ');
            errorMessages = `<ul class="p-l-half">`;
            errors.forEach(error => {
              errorMessages += `<li>${error}</li>`;
            });
            errorMessages += `<ul>`;

            // Use pre-build error messages
            this.alert.description = errorMessages;

            // Use error message from backend
            if (errorMessage) {
              this.alert.title = errorMessage;
            }
          }
        }

        throw new Error(error, errorMessage);
      } finally {
        this.isCalculateResult = false;
      }
    },

    getFilteredMachineGroupsByGroupName(machines, groupNames) {
      return machines.filter(group => {
        const { label } = group;
        return groupNames.includes(label);
      });
    },

    getDishMaterialById(id) {
      return this.$store.getters[`${WEBROOMHEATCALC_MODULE}/dishMaterialById`](id);
    },

    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        // First column name
        if (index === 0) {
          sums[index] = this.$t('TOTAL');
          return;
        }

        // Sum only valid cols
        const validCols = ['Total', 'Latent', 'Sensible'];
        if (!validCols.includes(column.property)) {
          sums[index] = '';
          return;
        }

        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);

            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = '';
        }
      });

      // Loop over all sums and alter sum to only have 2 digits
      const result = sums.map(sum => {
        if (Number.isNaN(Number.parseFloat(sum))) {
          return sum;
        } else {
          return Number(sum).toFixed(2) + ' kW';
        }
      });
      return result;
    },

    handleUpdateResults(result) {
      const { Total, Sensible, Latent } = result;
      if (Total >= 0 && Sensible >= 0 && Latent >= 0) {
        this.result = Object.assign({}, result);
      }
    },

    init() {
      this.$store.dispatch(`${WEBROOMHEATCALC_MODULE}/GET_DISHES`);
      this.$store.dispatch(`${WEBROOMHEATCALC_MODULE}/GET_DISH_MATERIALS`);
      this.$store.dispatch(`${WEBROOMHEATCALC_MODULE}/GET_DISH_CATEGORIES`);
      this.$store.dispatch(`${WEBROOMHEATCALC_MODULE}/GET_MACHINES`);
      this.$store.dispatch(`${WEBROOMHEATCALC_MODULE}/GET_MACHINES_CONFIGURED`);
    },

    isFormValid(form) {
      let result = false;
      this.$refs[form].validate(valid => {
        result = valid;
      });
      return result;
    },

    async onClickAddDish() {
      this.isDishFormValid = this.isFormValid('dishForm');
      this.alert.visible = !this.isDishFormValid;

      if (this.isDishFormValid) {
        const data = await this.calculateDish();

        const result = {
          Sensible: data.Sensible,
          Latent: data.Latent,
          Total: data.Total
        };

        const dish = Object.assign({}, result, this.dish);
        this.compilation.push(dish);
        this.handleUpdateResults(data);
      } else {
        this.showDishValidationAlert();
      }
    },

    onClickAddMachine(selectedMachine) {
      this.onClickMachineCalc(selectedMachine);
      const { DisplayName } = selectedMachine;
      const { Sensible, Latent, Total } = this.result;
      const machine = {
        DisplayName: DisplayName,
        Sensible,
        Latent,
        Total
      };
      this.compilation.push(machine);
    },

    async onClickDishCalc() {
      this.isDishFormValid = this.isFormValid('dishForm');
      this.alert.visible = !this.isDishFormValid;

      if (this.isDishFormValid) {
        const data = await this.calculateDish();
        console.log('--', data);
        if (data) {
          this.handleUpdateResults(data);
        }
      } else {
        this.showDishValidationAlert();
      }
    },

    onClickMachineCalc(selectedMachine) {
      const { HeatFlow } = selectedMachine;

      if (HeatFlow) {
        const { Total, Sensible, Latent } = HeatFlow;
        if (Total >= 0 && Sensible >= 0 && Latent >= 0) {
          this.result = Object.assign({}, HeatFlow);
        }
      }
    },

    onClickDeleteItemInCompilation(index) {
      this.compilation.splice(index, 1);
    },

    onClickOpenDishLibrary() {
      this.$store.commit(`${DIALOG_MODULE}/OPEN_DIALOG`, {
        title: this.$t('STANDARD_VALUES_FOR_WASH_WARE_PARAMETERS'),
        data: this.dishesGroupByCategory,
        width: '50%',
        loadComponent: () => import(/* webpackChunkName: "dishlibrary" */ '@/components/DishLibrary.vue')
      });
    },

    registerEvents() {
      // Listen to the event that will be fired if a dish has been selected
      this.$events.$on('dishlibrary:select-dish', dish => {
        const { DisplayName, HeatFlowParameter } = dish;
        if (HeatFlowParameter) {
          this.dish = Object.assign({}, this.dish, HeatFlowParameter);
          this.dish.DisplayName = DisplayName;
        }
      });
    },

    resetResults() {
      forEach(this.result, (value, key) => {
        this.result[key] = null;
      });
    },

    resetAlert() {
      this.alert.visible = false;
      this.alert.description = '';
      this.alert.title = '';
    },

    showDishValidationAlert() {
      this.alert.type = 'error';
      this.alert.title = this.$t('ERROR');
      this.alert.description = this.$t('PLEASE_FILL_OUT_MANDATORY_FIELDS');
    }
  }
};
</script>

<style lang="scss">
.webroomheatcalc {
  .container {
    &__item {
      border: 1px solid $c-light-gray;
      width: 100%;

      &--active {
        border-color: $c-brand;
      }
    }

    &__item-headline {
      border-bottom: 1px solid $c-light-gray;
      // font-weight: 700;
      font-size: 1.2rem;
      margin-top: 0;
      padding: 1rem;
    }
  }

  .el-tabs--border-card {
    box-shadow: none;
  }

  .el-tabs__header {
    margin-bottom: 0;
  }
  .el-tabs__content {
    border: 1px solid $c-light-gray;
    border-top-color: transparent;
    padding: 2rem 1rem 1rem 1rem;
  }
}
</style>
