<template>
  <div class="webtree">
    <div class="container">
      <div class="container__item" v-loading="isLoadingTree">
        <h3 class="font--regular-plus font--w-700 m-t-0">{{ $t('TREE') }}</h3>
        <el-input :placeholder="$t('FILTER_LIST')" v-model="filterText" clearable @change="onChangeFilter"> </el-input>
        <el-tree
          :data="tree"
          :props="defaultProps"
          @node-click="handleNodeClick"
          node-key="Id"
          :default-expanded-keys="defaultExpandedKeys"
          :highlight-current="true"
          :empty-text="$t('NO_DATA_AVAILABLE')"
          class="tree m-t-1"
        >
          <span slot-scope="{ node, data }">
            <i :class="`m-r-half fa ${data.FileIconDefinition}`" v-if="data.FileIconDefinition.length"></i>
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
      </div>

      <div class="container__item no-padding">
        <h3 class="container__item-headline">
          {{ $t('PREVIEW') }}<span v-if="previewItemDescriptionLanq">: {{ previewItemDescriptionLanq }}</span>
        </h3>
        <div class="preview__card p-1">
          <div v-if="hasPreviewItem">
            <figure class="preview__image-container" v-if="get(previewItem, 'FileExtension') === 'PDF'">
              <img
                :src="previewItemImage"
                class="preview__image"
                :alt="previewItemDescriptionLanq"
                @click="onClickPreviewImage(previewItem)"
              />
            </figure>

            <div v-loading="isLoadingToolTip">
              <div v-if="previewTooltip.length" v-html="previewTooltip" class="m-t-2"></div>
            </div>

            <div class="m-t-2 text-center" v-if="get(previewItem, 'Filename')">
              <a :href="previewItemPath" class="el-button el-button--primary" target="_blank">
                <i class="fa fa-cloud-download m-r-half"></i>
                {{ $t('DOWNLOAD') }} {{ previewItem.Filename }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import globalMixins from '@/shared/mixins/index';
import { MODULE_NAME as DIALOG_MODULE } from '@/store/modules/dialog';

export default {
  name: 'WEBTREE',

  mixins: [globalMixins],

  props: {
    data: {
      type: Object,
      required: false
    }
  },

  computed: {
    ...mapGetters({
      isLoadingTree: 'webtree/isLoading',
      tree: 'webtree/tree'
    }),

    defaultExpandedKeys() {
      let result = [];
      if (this.tree.length < 2) {
        result.push(1);
      }
      return result;
    },

    hasPreviewItem() {
      return get(this.previewItem, 'Id', false);
    },

    previewItemPath() {
      let result = '';
      const path = get(this.previewItem, 'Path');
      if (path) {
        result = this.getDocumentHandlerSrc(path);
      }
      return result;
    },

    previewItemDescriptionLanq() {
      return get(this.previewItem, 'DescriptionLanq');
    },

    previewItemImage() {
      let result = '';
      const path = get(this.previewItem, 'Path');
      if (path) {
        result = this.getImageHandlerSrc(path, { height: 400 });
      }
      return result;
    }
  },

  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'DescriptionLanq'
      },
      filterText: '',
      isLoadingToolTip: false,
      previewItem: {},
      previewTooltip: ''
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    get,

    async handleNodeClick(node) {
      const children = get(node, 'children', []);
      const InfoTooltipNumber = get(node, 'Tooltip');
      if (!children.length) {
        this.previewItem = Object.assign({}, node);
      }

      if (InfoTooltipNumber) {
        this.isLoadingToolTip = true;
        const response = await this.$store.dispatch('GET_TOOLTIP', { InfoTooltipNumber });
        this.previewTooltip = get(response, 'data.RESPONSE', '');
        this.isLoadingToolTip = false;
      } else {
        this.previewTooltip = '';
      }
    },

    init() {
      this.$store.dispatch('webtree/FETCH_TREE');
    },

    onChangeFilter(value) {
      this.$store.commit('webtree/SET_FILTER', { query: value });
    },

    onClickPreviewImage(previewItem) {
      let result = '';
      const path = get(previewItem, 'Path');
      const DescriptionLanq = get(previewItem, 'DescriptionLanq');
      if (path) {
        result = this.getImageHandlerSrc(path, { height: 800 });
      }

      if (result.length) {
        this.$store.commit(`${DIALOG_MODULE}/OPEN_DIALOG`, {
          content: `<figure class="text-center">
              <img src='${result}' alt="${DescriptionLanq}" />
            </figure>`
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.webtree {
  // max-height: 50rem;
  overflow: auto;
}

.container {
  display: flex;
  width: 100%;
  max-width: initial;
  min-height: 10rem;

  &__item {
    border: 1px solid $c-light-gray;
    padding: 1rem;
    width: 100%;

    @media screen and (min-width: $screen-md) {
      width: 50%;

      &:first-child {
        margin-right: 1rem;
      }
    }
  }

  &__item-headline {
    border-bottom: 1px solid $c-light-gray;
    font-weight: 700;
    font-size: 1.2rem;
    margin-top: 0;
    padding: 1rem;
  }
}

.tree {
  border: 1px solid $c-light-gray;

  @media screen and (min-width: $screen-md) {
    max-height: 60vh;
    overflow: auto;
  }
}

.preview__card {
  @media screen and (min-width: $screen-md) {
    // height: 100%;
  }
}

.preview__image-container {
  // border: 1px solid $c-light-gray;
  cursor: pointer;
  text-align: center;
  margin: 0;
  width: 100%;
}

.preview__image {
  border: 1px solid $c-light-gray;
}
</style>
